import React from "react";
import {
  Button,
  Container,
  Typography,
  CssBaseline,
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SignInStatus } from "./states";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.8rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "3.6rem",
  },
};

interface Props {
  signInStatus: SignInStatus;
}

const Home = (props: Props) => {
  return (
    <Container component="main" sx={{ mb: 2 }} maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="./imgs/logo.png" alt="Readable" width="480" />
        <Typography
          variant="h5"
          component="h2"
          align="center"
          color="#84919e"
          sx={{ mt: 4, mb: 8 }}
        >
          将英文 PDF 文件翻译成中文，保留模版和布局。
        </Typography>
        <img
          src="./imgs/home_catch.png"
          alt="将英文 PDF 文件翻译成中文，保留模版和布局。"
          style={{ maxWidth: 1000, width: "100%" }}
        />
        <ThemeProvider theme={theme}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            fontWeight="fontWeightMedium"
            sx={{ mt: 8, mb: 8 }}
          >
            在线翻译 PDF 文件
          </Typography>
        </ThemeProvider>
        <Grid container rowSpacing={8} columnSpacing={2}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img1.png"
              alt="翻译不影响版面布局"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
            翻译不影响版面布局
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img2.png"
              alt="可以在2个页面左右对比翻译前后的PDF文件"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
            可以在2个页面左右对比
            <br />
            翻译前后的PDF文件
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img3.png"
              alt="翻译文件大约只需要 30 秒"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
            翻译文件大约只需要 30 秒
            </Typography>
          </Grid>
        </Grid>
        {props.signInStatus === SignInStatus.INITIALSTATE && (
          <Box sx={{ height: 104 }} />
        )}
        {props.signInStatus === SignInStatus.NOTSIGNEDIN && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              点击此处在线翻译（免费版）
            </Button>
            <Button
              variant="contained"
              to="/pricing"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              注册专业会员（免费试用一周）
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.VERYFING && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              点击此处在线翻译（免费版）
            </Button>
            <Button
              variant="contained"
              size="large"
              to="/signup"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              重新发送确认邮件
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.PURCHASING && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              点击此处在线翻译（免费版）
            </Button>
            <Button
              variant="contained"
              size="large"
              to="/pricing"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              填写付款方式
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.PREMIUM && (
          <Button
            variant="contained"
            to="/translate"
            sx={{ mt: 8, width: 200, height: 40 }}
            component={RouterLink}
          >
            点击此处在线翻译
          </Button>
        )}
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          align="center"
          sx={{ mt: 16 }}
        >
          专业会员简介
        </Typography>
        <iframe
          width="400"
          height="225"
          src="https://www.youtube.com/embed/r_-tzhWvUOE?si=_Tq5BAFvOKL90WN_"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Box>
    </Container>
  );
};

export default Home;
