import React, { useState, useRef, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Container,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  CssBaseline,
  Box,
  Snackbar,
  Paper,
} from "@mui/material";
import axios from "axios";
import { INITIALSTATE, PREMIUM, SignInStatus } from "./states";
import AltButton from "./altButton";

const REACT_APP_READABLE_FILE_URL: string =
  process.env.REACT_APP_READABLE_FILE_URL!;

interface Props {
  user: any;
  signInStatus: SignInStatus;
  expire: number;
}

const Pro = (props: Props) => {
  const inputRef = useRef<any>(null);
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>();
  const [state, setState] = useState(0);
  const [jaFilepath, setJaFilepath] = useState<string>();
  const [alFilepath, setAlFilepath] = useState<string>();
  const [prFilepath, setPrFilepath] = useState<string>();
  const processFile = (source: File) => {
    if (!source) {
      return;
    }
    setState(1);
    const params = new FormData();
    params.append("file", source);
    params.append("token", props.user.multiFactor.user.accessToken);
    params.append("original_filename", source.name);
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "auto/", params)
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("sizeover" in response.data) {
          setSnackMessage(
            "文件大小过大，请分成 100 页，大小不超过 50 MB。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("undefined" in response.data) {
          setState(0);
        } else if ("error" in response.data) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("notext" in response.data) {
          setSnackMessage(
            "未检测到文本 用鼠标拖动 PDF 文件中的文本等，检查是否可以选择或嵌入文本。 适当分割文件。 如果原始文件中没有文本信息，则无法使用可读功能。 要么放弃该文件，要么找到嵌入文本的等效文件/纸张并使用它。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("notpdf" in response.data) {
          setSnackMessage("仅支持 PDF 文件。");
          setState(0);
          setShowSnack(true);
        } else {
          setJaFilepath(response.data.ja);
          setAlFilepath(response.data.alt);
          setPrFilepath(response.data.print);
          setState(2);
        }
      })
      .catch((e) => {
        if (e.response.status === 413) {
          setSnackMessage(
            "文件大小过大，请分成 100 页，大小不超过 50 MB。"
          );
          setState(0);
          setShowSnack(true);
        } else {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setState(0);
          setShowSnack(true);
        }
      });
  };
  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    const files = e.dataTransfer.files;
    if ((state === 0 || state >= 2) && files.length === 1 && files[0]) {
      processFile(files[0]);
    }
  };
  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onFileInputChange = (e: any) => {
    const source = e.target.files[0];
    if (!source) {
      return;
    }
    processFile(source);
  };
  if (props.signInStatus === INITIALSTATE) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
      </Container>
    );
  }
  if (props.signInStatus !== PREMIUM) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          帐户未注册。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="contained"
            to="/pricing"
            sx={{ mt: 4, width: 200, height: 40 }}
            component={RouterLink}
          >
            在此注册
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Container
        component="main"
        sx={{ mb: 2 }}
        maxWidth="lg"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 2 }}
          >
            1.请上传需要翻译的PDF文件。
          </Typography>
          <img src="./imgs/upload.png" alt="上传" width="200" />
          {(state === 0 || state >= 2) && (
            <Button
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              上传 PDF 文件
            </Button>
          )}
          {state === 1 && (
            <CircularProgress sx={{ mt: 2, mb: 4, height: 40 }} />
          )}
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            2.下载翻译结果
          </Typography>
          {state < 2 && (
            <img
              src="./imgs/download_disable.png"
              alt="下载翻译结果"
              width="300"
            />
          )}
          {state >= 2 && (
            <img
              src="./imgs/download.png"
              alt="下载翻译结果"
              width="300"
            />
          )}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            {state < 2 && (
              <Button variant="contained" disabled>
                下载中文 PDF
              </Button>
            )}
            {state >= 2 && (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    REACT_APP_READABLE_FILE_URL + jaFilepath,
                    "_blank"
                  );
                }}
              >
                下载中文 PDF
              </Button>
            )}
            <AltButton
              disable={state < 2}
              // @ts-ignore
              alFilepath={alFilepath}
              // @ts-ignore
              prFilepath={prFilepath}
            />
          </Stack>
          <Paper variant="outlined" sx={{ mt: 4, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
            建议使用备用 PDF。 在 Chrome 浏览器的 PDF 查看器中，选择右上角的三点按钮可显示两页页面。
            </Typography>
            <Typography component="h4">
            供打印机打印的备用 PDF 文件在开头插入了空白页。 如果在打印机上双面打印，则可将各页作为一个展开页阅读。
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={{ mt: 3, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
            该扩展程序允许您通过点击插件图标直接翻译网页上的 PDF 文件，无需访问 Readable 网站。
            </Typography>
            <Typography component="h4">
              <a
                href="https://chrome.google.com/webstore/detail/readable/pmhcplemclcflofgnjfhoilpkclnjnfh?hl=zh-CN"
                target="_blank"
                rel="noreferrer"
              >
                安装Chrome浏览器扩展程序
              </a>
            </Typography>
            <Typography component="h4">
            设置插件将使您的翻译体验更加流畅。
            </Typography>
            <Typography component="h4">使用方法</Typography>
            <ol>
              <li>
              在 Chrome 浏览器中打开 PDF 文件。
              </li>
              <li>插件图标变为蓝色后点击它。</li>
              <li>（如果您尚未登录 Readable，请登录后重新开始。）</li>
              <li>扩展图标为淡蓝色 ... 请等待翻译结果。</li>
              <li>稍等片刻，翻译文件就会在新的标签页中打开。</li>
            </ol>
          </Paper>
          {props.expire !== 0 && (
            <Typography component="h4">
              账户于 {" "}
              {new Date(props.expire * 1000).getFullYear()} 年{" "}
              {new Date(props.expire * 1000).getMonth() + 1} 月{" "}
              {new Date(props.expire * 1000).getDate()} 日到期。
            </Typography>
          )}
        </Box>
      </Container>
      <input
        hidden
        type="file"
        onChange={onFileInputChange}
        accept={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? undefined : '.pdf'}
        ref={inputRef}
      />
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Pro;
