import React, { useState, useRef, Fragment, ChangeEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  CssBaseline,
  Box,
  Snackbar,
  Link,
  Paper,
} from "@mui/material";
import AltButton from "./altButton";

const REACT_APP_READABLE_FILE_URL: string =
  process.env.REACT_APP_READABLE_FILE_URL!;

const Free = () => {
  const inputRef = useRef<any>(null);
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("error");
  const [snackMessage, setSnackMessage] = useState<any>();
  const [url, setURL] = useState();
  const [state, setState] = useState(0);
  const [originalFilename, setOriginalFilename] = useState("");
  const [UUID, setUUID] = useState(0);
  const [jaFilepath, setJaFilepath] = useState<string>();
  const [alFilepath, setAlFilepath] = useState<string>();
  const [prFilepath, setPrFilepath] = useState<string>();
  const sendMessageToBackend = (message: string) => {
    axios.post(
      process.env.REACT_APP_READABLE_BACKEND_URL + "log?message=" + message
    );
  };
  const processFile = async (source: File) => {
    setState(1);
    setOriginalFilename(source.name);
    const params = new FormData();
    params.append("file", source);
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "toquery/", params, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("sizeover" in response.data) {
          setSnackMessage(
            <Fragment>
              文件太大。免费版本只支持少于20页且小于20MB的文件。如果您需要翻译较大的文件，请考虑使用
              <Link
                to="/pricing"
                component={RouterLink}
                onClick={() => sendMessageToBackend("click_sizeover")}
              >
                Readable Pro
              </Link>
              ，成为专业会员。
            </Fragment>
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
          sendMessageToBackend("show_sizeover");
        } else if ("undefined" in response.data) {
          setState(0);
        } else if ("error" in response.data) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("notext" in response.data) {
          setSnackMessage(
            "未检测到文本。请尝试在PDF阅读器中打开该文件，拖动鼠标确认是否有可以选中的文本。您可以尝试对文件进行分割，如果上传的文件中没有识别到文本，则Readable无法进行翻译。您也可以尝试寻找与该文件内容相同的其他PDF版本。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("notpdf" in response.data) {
          setSnackMessage("仅支持 PDF 文件。");
          setState(0);
          setShowSnack(true);
        } else {
          setUUID(response.data.uuid);
          setURL(response.data.url);
          if (decodeURIComponent(response.data.url).length >= 3040) {
            setSnackMessage(
              <Fragment>
                超过3000个字符。使用DeepL Free可能会遇到字数限制（DeepL Pro用户不会遇到此类问题）。如果您需要翻译字数较多的文件，请考虑使用<Link
                  to="/pricing"
                  component={RouterLink}
                  onClick={() => sendMessageToBackend("click_5000")}
                >
                  Readable Pro
                </Link>，成为专业会员。
              </Fragment>
            );
            setSnackSeverity("warning");
            setShowSnack(true);
            sendMessageToBackend("show_5000");
          }
          setState(2);
        }
      })
      .catch((e) => {
        if (e.response.status === 413) {
          setSnackMessage(
            <Fragment>
              文件太大。免费版本只支持少于20页且小于20MB的文件。如果您需要翻译较大的文件，请考虑使用
              <Link
                to="/pricing"
                component={RouterLink}
                onClick={() => sendMessageToBackend("click_sizeover")}
              >
                Readable Pro
              </Link>
              ，成为专业会员。
            </Fragment>
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
          sendMessageToBackend("show_sizeover");
        } else {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        }
      });
  };
  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    const files = e.dataTransfer.files;
    console.log(files);
    if ((state === 0 || state >= 2) && files.length === 1 && files[0]) {
      processFile(files[0]);
    }
  };
  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const source = e.target.files[0];
    if (!source) {
      return;
    }
    processFile(source);
  };
  const onClickPost = async () => {
    setState(3);
    // @ts-ignore
    const body = document.getElementById("text").value;
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "generate/", {
        body: body,
        uuid: UUID,
        original_filename: originalFilename,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else if ("shorter" in response.data) {
          setSnackMessage(
            "错误：请确保您正确粘贴了翻译结果。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else if ("error" in response.data) {
          setSnackMessage(
            "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else {
          setJaFilepath(response.data.ja);
          setAlFilepath(response.data.alt);
          setPrFilepath(response.data.print);
          setState(4);
        }
      })
      .catch((e) => {
        setSnackMessage(
          "出现意外错误。您上传的文件可能不符合 Readable 的标准。"
        );
        setSnackSeverity("error");
        setShowSnack(true);
        setState(2);
      });
  };
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Container
        component="main"
        sx={{ mb: 2 }}
        maxWidth="lg"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 2 }}
          >
            1. 请上传需要翻译的PDF文件。
          </Typography>
          <img src="./imgs/upload.png" alt="上传 PDF 文件" width="200" />
          {(state === 0 || state >= 2) && (
            <Button
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              上传 PDF 文件
            </Button>
          )}
          {state === 1 && (
            <CircularProgress sx={{ mt: 2, mb: 4, height: 40 }} />
          )}
          <Typography
            variant="subtitle2"
            component="h2"
            align="center"
            color="#84919e"
            sx={{ mb: 8 }}
          >
            如果您使用 DeepL Pro，一次可上传至多 20 页的文件。
            <br />
            如果您使用 DeepL Free，请一次上传1页。
          </Typography>
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            2. 打开 DeepL，复制右侧的翻译结果
          </Typography>
          {state !== 2 && (
            <Button
              disabled
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
            >
              打开 DeepL
            </Button>
          )}
          {state === 2 && (
            // @ts-ignore
            <Button
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
            >
              打开 DeepL
            </Button>
          )}
          <img
            src="./imgs/deepl.png"
            alt="打开 DeepL，复制右侧的翻译结果"
            style={{ maxWidth: 600, width: "100%" }}
          />
          <TextField
            sx={{ mt: 4, maxWidth: 600, width: "100%z" }}
            id="text"
            multiline
            rows={5}
            label="粘贴翻译结果"
            variant="outlined"
          />
          {state < 2 && (
            <Button
              disabled
              variant="contained"
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              粘贴完成
            </Button>
          )}
          {state === 2 && (
            <Button
              onClick={onClickPost}
              variant="contained"
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              粘贴完成
            </Button>
          )}
          {state >= 4 && (
            <Button
              variant="contained"
              disabled
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              完成
            </Button>
          )}
          {state === 3 && (
            <CircularProgress sx={{ mt: 2, mb: 8, height: 40 }} />
          )}
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            3.下载翻译结果
          </Typography>
          {state < 4 && (
            <img
              src="./imgs/download_disable.png"
              alt="下载翻译结果"
              width="300"
            />
          )}
          {state >= 4 && (
            <img
              src="./imgs/download.png"
              alt="下载翻译结果"
              width="300"
            />
          )}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            {state < 4 && (
              <Button variant="contained" disabled>
                下载中文 PDF 文件
              </Button>
            )}
            {state >= 4 && (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    REACT_APP_READABLE_FILE_URL + jaFilepath,
                    "_blank"
                  );
                }}
              >
                下载中文 PDF 文件
              </Button>
            )}
            <AltButton
              disable={state < 4}
              // @ts-ignore
              alFilepath={alFilepath}
              // @ts-ignore
              prFilepath={prFilepath}
            />
          </Stack>
          <Paper variant="outlined" sx={{ mt: 4, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
            我们推荐您使用中英文交替版PDF文件。如果您使用Chrome浏览器自带的PDF阅读器，请点击右上角的三点按钮，选择双页视图以同时显示中文翻译和英语原文。
            </Typography>
            <Typography component="h4">
            用于打印机的交替版文件在开头插入了一页空白页。确保您使用打印机的双面左右打印功能时，文件翻开后中文翻译在左侧，对应英文原文在右侧。
            </Typography>
          </Paper>
          {/* @ts-ignore */}
          <Paper
            variant="outlined"
            severity="info"
            align="center"
            sx={{ mt: 8, pt: 1, pb: 1, pl: 2, pr: 2 }}
          >
            <Link to="/pricing" component={RouterLink}>
            专业会员
            </Link>
            可以直接获得翻译版，无需复制粘贴
            <br />
            <Typography sx={{ fontWeight: "bold", color: "#1976d2" }}>
              可免费试用一周！
            </Typography>
          </Paper>
        </Box>
      </Container>
      <input
        hidden
        type="file"
        onChange={onFileInputChange}
        accept={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? undefined : '.pdf'}
        ref={inputRef}
      />
      <Snackbar
        open={showSnack}
        autoHideDuration={20000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          // @ts-ignore
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Free;
