import * as React from "react";
import { Link, Container, Box, Typography, Divider, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      © NextLab Inc. All Rights Reserved.
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#fcfcfc",
      }}
    >
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={1.5} align="center">
            <Link
              to="/"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              首页
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2} align="center">
            <Link
              href="https://share.hsforms.com/1kclB_gOmSuezIFa1u2PWHwe4idn "
              color="inherit"
              underline="hover"
              target="_blank"
              rel="noreferrer"
            >
              咨询
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2} align="center">
            <Link
              href="https://readable-pdf.notion.site/3ea2acf75d7245428e9d77e2192cf5cb"
              color="inherit"
              underline="hover"
              sx={{ ml: 1, mr: 1 }}
              target="_blank"
              rel="noreferrer"
            >
              常见问题
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2.5} align="center">
            <Link
              to="/privacy"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              隐私政策
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={1.5} align="center">
            <Link
              to="/terms"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              条款和条件
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2.5} align="center">
            <Link
              to="/tokutei"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              关于具体商业交易的说明
            </Link>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
