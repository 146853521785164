import React, { useState, Fragment } from "react";
import {
  useSearchParams,
  useNavigate,
  Link as RouterLink,
} from "react-router-dom";
import axios from "axios";
import {
  Link,
  Container,
  Box,
  CssBaseline,
  TextField,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import firebase from "firebase/compat/app";
import { NOTSIGNEDIN, VERYFING, PURCHASING, PREMIUM } from "./states";

const plans: any =
  process.env.REACT_APP_READABLE_PRODUCT === "False"
    ? {
        monthly: "price_1NyPCWEhv4pZkJMeD6Qd5HRe",
        yearly: "price_1NyPCkEhv4pZkJMe4BMejEa0",
      }
    : {
        monthly: "price_1O1NOdEhv4pZkJMeoxRl0KcI",
        yearly: "price_1O1NP5Ehv4pZkJMeV3KJKUnA",
      };

interface Props {
  handleSignUp: any;
}

const SignUpForm = (props: Props) => {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          会员注册
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={props.handleSignUp}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="电子邮件地址"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="密码（至少 6 个字符，字母和数字组合）"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <FormGroup>
              <FormControlLabel
                name="agree"
                control={<Checkbox />}
                label={
                  <Fragment>
                    同意<Link
                      to="/terms"
                      component={RouterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      使用条款
                    </Link>
                    和
                    <Link
                      to="/privacy"
                      component={RouterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      隐私政策
                    </Link>
                  </Fragment>
                }
              />
            </FormGroup>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            注册
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/login" component={RouterLink}>
              登录
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/pricing" component={RouterLink}>
              返回计划选择
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

interface VeryfingProps {
  user: any;
  handleResubimt: any;
}

const Veryfing = (props: VeryfingProps) => {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        确认邮件已发送至 {props.user.multiFactor.user.email}。
      </Typography>
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mb: 2 }}
      >
        请点击确认邮件中的链接完成注册。
      </Typography>
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mb: 2 }}
      >
        如果收不到邮件，请尝试查看垃圾邮件文件夹。
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={props.handleResubimt}
        >
          重新发送
        </Button>
      </Box>
    </Container>
  );
};

interface PurchasingProps {
  plan: any;
  db: any;
  user: any;
  handleTicket: any;
}

const Purchasing = (props: PurchasingProps) => {
  const [isLoading, setLoading] = useState(false);
  const plan = props.plan;
  const handleSubscribe = async (price: string) => {
    setLoading(true);
    const subscriptions = await props.db
      .collection("customers")
      .doc(props.user.uid)
      .collection("subscriptions")
      .get();
    const selectedPrice = {
      price: price,
      quantity: 1,
    };
    const checkoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: true,
      line_items: [selectedPrice],
      success_url: process.env.REACT_APP_READABLE_URL + "thanks?plan=" + plan,
      cancel_url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan,
      trial_from_plan: subscriptions.size === 0,
      allow_promotion_codes: true,
      locale: 'zh',
      metadata: {
        key: "value",
      },
    };

    const docRef = await props.db
      .collection("customers")
      .doc(props.user.uid)
      .collection("checkout_sessions")
      .add(checkoutSession);
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap: any) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
        document
          .querySelectorAll("button")
          .forEach((b) => (b.disabled = false));
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };
  if (plan === "ticket") {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          {props.user.multiFactor.user.email} 已验证。
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          请输入激活码。
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          激活码是一个 10 位字母数字代码，如 AB98CDEF7G。
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={props.handleTicket}
          sx={{ mt: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="ticket"
                label="激活码"
                name="ticket"
                sx={{ mt: 2, mb: 1 }}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
          >
            提交
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="text"
            sx={{ mt: 1, mb: 2 }}
            to="/pricing"
            component={RouterLink}
          >
            使用信用卡订阅
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        {props.user.multiFactor.user.email} 已验证。
      </Typography>
      {plan === "monthly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          您已选择月付计划。
        </Typography>
      )}
      {plan === "yearly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          您已选择年付计划。
        </Typography>
      )}
      {plan !== "monthly" && plan !== "yearly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          无法加载计划信息。 请重新选择订阅计划。
        </Typography>
      )}
      {(plan === "monthly" || plan === "yearly") && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              handleSubscribe(plans[plan]);
            }}
          >
            填写您的付款方式
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          variant="text"
          sx={{ mt: 1, mb: 2 }}
          to="/pricing"
          component={RouterLink}
        >
          重新选择订阅计划
        </Button>
      </Box>
      {isLoading && (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Fragment>
      )}
    </Container>
  );
};

const Premium = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        已注册。
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          variant="contained"
          to="/translate"
          sx={{ mt: 4, width: 200, height: 40 }}
          component={RouterLink}
        >
          点击此处获取译文
        </Button>
      </Box>
    </Container>
  );
};

interface SignUpProps {
  user: any;
  db: any;
  signInStatus: number;
  setSignInStatus: any;
  setExpire: any;
}

const SignUp = (props: SignUpProps) => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("error");
  const [snackMessage, setSnackMessage] = useState<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const handleSignUp = (e: any) => {
    e.preventDefault();
    if (!e.target.agree.checked) {
      setSnackMessage("必须同意使用条款。");
      setSnackSeverity("error");
      setShowSnack(true);
      return;
    }
    const email = e.target.email.value;
    const password = e.target.password.value;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        axios
        .post(process.env.REACT_APP_READABLE_BACKEND_URL + "verify-email/", {
          email: email,
          url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setSnackMessage("电子邮件地址无效。");
        } else if (error.code === "auth/weak-password") {
          setSnackMessage("密码太弱。");
        } else if (error.code === "auth/email-already-in-use") {
          setSnackMessage(
            "电子邮件地址已注册。 请登录。"
          );
        } else {
          setSnackMessage(
            "注册失败。 请检查您输入的信息是否正确。"
          );
        }
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  const handleResubimt = () => {
    if (props.user) {
      // @ts-ignore
      firebase
        .auth()
        .currentUser.reload()
        .then(() => {
          // @ts-ignore
          if (firebase.auth().currentUser.emailVerified) {
            setSnackMessage("已获得认证。");
            setSnackSeverity("error");
            setShowSnack(true);
          } else {
            axios
            .post(process.env.REACT_APP_READABLE_BACKEND_URL + "verify-email/", {
              email: props.user.email,
              url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan
            }, {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }).then(() => {
                setSnackMessage("已重新发送确认邮件。");
                setSnackSeverity("info");
                setShowSnack(true);
              })
              .catch((e: any) => {
                if (e.code === "auth/too-many-requests") {
                  setSnackMessage(
                    "上次传输的时间间隔太短，请至少等待一分钟后再试。"
                  );
                  setSnackSeverity("error");
                  setShowSnack(true);
                } else {
                  setSnackMessage("错误。");
                  setSnackSeverity("error");
                  setShowSnack(true);
                }
              });
          }
        });
    }
  };
  const handleTicket = (e: any) => {
    e.preventDefault();
    const ticket = e.target.ticket.value;
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "ticketauth/", {
        ticket: ticket,
        uid: props.user.uid,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage("出现意外错误。");
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("invalid" in response.data) {
          setSnackMessage(
            "激活码不正确。 激活码是一个 10 位字母数字代码，如 AB98CDEF7G。 请检查您输入的代码是否正确。 如果问题仍然存在，请联系我们。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("used" in response.data) {
          setSnackMessage(
            "激活码已被使用。如果经重新确认问题仍然存在，请联系我们。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("ok" in response.data) {
          props.setSignInStatus(PREMIUM);
          props.setExpire(response.data.expire);
          navigate("/thanks?plan=ticket");
        } else {
          setSnackMessage("出现意外错误。");
          setSnackSeverity("error");
          setShowSnack(true);
        }
      })
      .catch((e) => {
        setSnackMessage("出现意外错误。");
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  return (
    <Fragment>
      {props.signInStatus === NOTSIGNEDIN && (
        <SignUpForm handleSignUp={handleSignUp} />
      )}
      {props.signInStatus === VERYFING && (
        <Veryfing user={props.user} handleResubimt={handleResubimt} />
      )}
      {props.signInStatus === PURCHASING && (
        <Purchasing
          user={props.user}
          db={props.db}
          plan={plan}
          handleTicket={handleTicket}
        />
      )}
      {props.signInStatus === PREMIUM && <Premium />}
      <Snackbar
        open={showSnack}
        autoHideDuration={30000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          // @ts-ignore
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SignUp;
