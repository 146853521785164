import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <h1>Readable利用規約</h1>
      <p>
        このReadable利用規約（以下「本規約」といいます。）は、株式会社ネクストラボ（以下「当社」といいます。）が提供する「Readable」（以下「本サービス」といいます。）をご利用いただく際の取り扱いにつき定めるものです。
      </p>
      <h3>第１条（本規約の適用範囲）</h3>
      <p>
        本規約は、本サービスを利用される方（以下「利用者」といいます。）すべてに適用されます。
      </p>
      <h3>第２条（本規約の変更）</h3>
      <p>
        当社は、利用者の承諾を得ることなく、本規約を変更することがあります。
      </p>
      <p>
        当社は、本規約を変更する場合には、その内容について当社所定の方法により利用者に通知するものとし、本規約の変更の効力は、当該通知を行った時点から生じるものとします。
      </p>
      <h3>第３条（会員登録等）</h3>
      <p>
        利用者は、本規約の内容を確認のうえ当社所定の方法で会員登録を行うものとします。当該会員登録が完了した時点で、利用者は、本規約の内容を承諾したものとし、利用者と当社との間で、本規約に基づいた本サービスの利用に関する契約が成立するものとします。
      </p>
      <p>
        前項に定めるほか、利用者は、本サービスを利用するにあたり当社が合理的に必要と認める情報を登録するものとし、かかる情報を提供しない場合には、本サービスを利用できず、また、当社の裁量で当該利用者の会員登録を停止または取り消しすることができるものとします。
      </p>
      <p>
        利用者は、会員登録に際し、利用者自身に関する情報をすべて正確に提供するものとし、虚偽もしくは不正確な情報又は利用者自身以外の情報を提供してはならないものとします。
      </p>
      <h3>第４条（登録情報の管理、変更）</h3>
      <p>
        利用者は、本サービスの利用に際して登録した情報（ID、パスワードを含み、以下「登録情報」といいます。）を、自己の責任で管理するものとし、これを第三者に利用させないものとします。
      </p>
      <p>
        利用者は、登録情報に変更が生じた場合には、速やかに当社所定の手続きに従い変更手続きを行うものとします。
      </p>
      <p>
        当社は、登録情報が不正確または虚偽であったために利用者が被った不利益および損害に関して、一切責任を負わないものとします。
      </p>
      <h3>第５条（個人情報等の取り扱い）</h3>
      <p>
        利用者の個人情報の取り扱いについては、当社が別途定める「株式会社ネクストラボ　プライバシーポリシー」に従うものとします。
      </p>
      <h3>第６条（本サービス利用に関する費用）</h3>
      <p>
        利用者は、当社に対し、当社所定の方法で本サービスの利用料（以下「利用料」といいます。）を支払うものとします。利用料及びその支払い方法は、以下のリンクから確認できるものとし、当社は、これらを当社の裁量で適宜変更できるものとします。
      </p>
      <p>
        <Link to="/pricing">https://readzh.com/tokutei</Link>
      </p>
      <p>当社は、理由の如何を問わず、受領した利用料を一切返還いたしません。</p>
      <h3>第７条（著作権、商標権等）</h3>
      <p>
        利用者が本サービスを通じてアップロードその他の方法で提供したものを除き、本サービス及び本サービスに使用されるすべてのコンテンツ（文章、画像、映像、音声、プログラム、ソフトウェア、コードその他の情報）の著作権その他の知的財産権、および本サービスに使用されるすべての商標（サービスマークおよびトレードマーク）に関する権利は、すべて当社に帰属します。
      </p>
      <h3>第８条（禁止行為）</h3>
      <p>
        利用者は、本サービスの利用にあたり、次の各号に掲げる行為を行わないものとします。
      </p>
      <ol>
        <li>
          当社または第三者の著作権、財産権その他の権利を侵害する行為または侵害するおそれのある行為。
        </li>
        <li>
          当社または第三者に不利益もしくは損害を与える行為又は不利益もしくは損害を与えるおそれのある行為。
        </li>
        <li>当社または第三者を差別または誹謗中傷する行為。</li>
        <li>本サービスまたは当社が提供する情報を営利目的で使用する行為。</li>
        <li>他の利用者による本サービスの利用またはアクセスを妨げる行為。</li>
        <li>法令、本規約または公序良俗に反する行為。</li>
        <li>その他当社が不適切と判断する行為。</li>
      </ol>
      <h3>第９条（会員資格の取り消し等）</h3>
      <p>
        利用者が次の各号に掲げる事由に該当する場合には、当社は、事前に通知することなく、利用者の会員資格を停止し、またはこれを取り消すことができるものとします。
      </p>
      <ol>
        <li>
          会員登録において、虚偽または不正確な情報を提供したことが判明した場合。
        </li>
        <li>第８条各号のいずれかに該当する行為を行った場合。</li>
        <li>その他当社が不適切と判断した場合。</li>
      </ol>
      <p>
        前項に基づく会員資格の取り消しにより、利用者が本サービスを利用することができず損害、損失その他の不利益を被ったとしても、当社は、一切責任を負わないものとします。
      </p>
      <h3>第１０条（退会）</h3>
      <p>
        利用者が本サービスの利用の終了を希望する場合には、当社所定の手続きに従い退会の届け出を行うものとします。
      </p>
      <p>
        当社は、利用者が前条各号に掲げる事由に該当した場合には、事前に通知することなく、利用者を退会させることができるものとします。
      </p>
      <h3>第１１条（本サービスの一時的な中断）</h3>
      <p>
        当社は、次の各号に掲げる事項のいずれかに該当する場合には、利用者に事前に通知することなく、一時的に本サービスの全部または一部の提供を中断することができるものとします。
      </p>
      <ol>
        <li>
          本サービスのシステムの保守点検、更新等を定期的または緊急に行う場合。
        </li>
        <li>
          地震、噴火、洪水、津波、火災、停電その他の不可抗力により、本サービスが提供できなくなった場合。
        </li>
        <li>
          その他運営上または技術上の理由により、当社が本サービスの一時的な中断が必要と判断した場合。
        </li>
      </ol>
      <h3>第１２条（免責）</h3>
      <p>
        当社は、本サービスの内容変更、中断、終了によって利用者に生じたいかなる損害、損失その他の不利益についても、一切責任を負いません。
      </p>
      <p>
        当社は、本サービスによって利用者に提供される情報の完全性、正確性、適合性及び合法性を保証するものではなく、本サービスを利用したことにより直接的または間接的に利用者に発生した損害、損失その他の不利益について、一切責任を負いません。
      </p>
      <p>
        本サービスの利用に伴い提供される利用者のデータ情報（登録情報に含まれる個人情報を除く）について、当社は何ら義務を負わず、一切責任を負いません。
      </p>
      <p>
        当社は、本サービスの安全性を保証しません。本サービスの利用が原因で、ウィルスその他悪質なソフトウェアが、利用者の端末、データ情報、その他の所有物に感染し、損害または不利益が生じた場合でも、一切責任を負いません。利用者は、利用者のコンピュータ端末その他の所有物に、最新のウィルス対策およびマルウェア対策のソフトウェアを自らの費用と責任で導入した上で、本サービスを利用するものとします。
      </p>
      <h3>第１３条（第三者損害に関する責任）</h3>
      <p>
        利用者は、本サービスの利用に関連して第三者に損害を与えた場合には、自己の責任と費用においてかかる損害を賠償し、または当該第三者との紛争を解決するものとし、当社に一切迷惑をかけないものとします。
      </p>
      <h3>第１４条（損害賠償）</h3>
      <p>
        利用者は、本サービスの利用に関連して当社に損害を与えた場合には、自己の責任と費用においてかかる損害を賠償するものとします。
      </p>
      <h3>第１５条（権利譲渡の禁止）</h3>
      <p>
        利用者は、当社の事前の書面による承諾がない限り、本規約に基づく権利または義務の全部もしくは一部を第三者に譲渡してはならないものとします。
      </p>
      <h3>第１６条（準拠法、管轄裁判所）</h3>
      <p>
        本規約の解釈および適用は、日本法に準拠するものとし、本サービスまたは本規約にかかわるすべての紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <p>以上</p>
      <p>２０２２年１１月１１日　制定</p>
      <p>２０２２年１２月９日　改訂</p>
    </Container>
  );
};

export default Terms;
