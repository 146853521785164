import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Link,
  Container,
  Box,
  CssBaseline,
  TextField,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";
import { SignInStatus } from "./states";

interface Props {
  signInStatus: SignInStatus;
}

const SignIn = (props: Props) => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.signInStatus === SignInStatus.VERYFING) {
      navigate("/signup");
    } else if (props.signInStatus === SignInStatus.PURCHASING) {
      navigate("/pricing");
    } else if (props.signInStatus === SignInStatus.PREMIUM) {
      navigate("/translate");
    }
  });
  const handleSignIn = (e: any) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(() => {
        setShowSnack(true);
        // @ts-ignore
        setSnackMessage("登录失败");
      });
  };
  if (props.signInStatus !== SignInStatus.NOTSIGNEDIN) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          登录
        </Typography>
        <Box component="form" noValidate onSubmit={handleSignIn} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="电子邮件地址"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="密码"
                type="password"
                id="password"
                autoComplete="password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登录
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/reset" component={RouterLink}>
              密码重置
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/pricing" component={RouterLink}>
              注册新账号
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignIn;
