import './style.css';
import React from "react";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from "./app";

const theme = createTheme({
  typography: {
    fontFamily: 'Noto Sans SC'
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
