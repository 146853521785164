import React, { useState, Fragment } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Toolbar,
  Box,
  Link,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import firebase from "firebase/compat/app";
import {
  NOTSIGNEDIN,
  VERYFING,
  PURCHASING,
  PREMIUM,
  SignInStatus,
} from "./states";

interface DrawerMenuProps {
  setDrawerOpened: (opened: boolean) => void;
}

const DrawerMenu = (props: DrawerMenuProps) => {
  return (
    <Box
      sx={{ width: 250, mt: 4 }}
      role="presentation"
      onClick={() => props.setDrawerOpened(false)}
    >
      <List>
        <ListItem disablePadding>
          {/* @ts-ignore */}
          <ListItemButton
            to="/"
            color="inherit"
            underline="hover"
            component={RouterLink}
          >
            <ListItemText primary="首页" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            href="https://share.hsforms.com/1kclB_gOmSuezIFa1u2PWHwe4idn "
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText primary="咨询" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          {/* @ts-ignore */}
          <ListItemButton
            to="/pricing"
            color="inherit"
            underline="hover"
            component={RouterLink}
          >
            <ListItemText primary="计划清单" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

interface Props {
  signInStatus: SignInStatus;
  ticketPremium: boolean;
}

const Header = (props: Props) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const useDrawer = useMediaQuery(theme.breakpoints.down("md"));
  const onClickBillingPortal = async () => {
    setLoading(true);

    // Call billing portal function
    const functionRef = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({ returnUrl: window.location.href, locale: "zh" });
    window.location.assign(data.url);
  };

  return (
    <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
      {useDrawer && (
        <Fragment>
          <MenuIcon
            onClick={() => setDrawerOpened(true)}
            style={{ cursor: "pointer" }}
          />
          <Drawer
            anchor={"left"}
            open={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <DrawerMenu setDrawerOpened={setDrawerOpened} />
          </Drawer>
        </Fragment>
      )}
      {!useDrawer && (
        <Fragment>
          <Link
            to="/"
            color="inherit"
            underline="hover"
            sx={{ ml: 2, mr: 1 }}
            component={RouterLink}
          >
            首页
          </Link>
          <Link
            href="https://share.hsforms.com/1kclB_gOmSuezIFa1u2PWHwe4idn "
            color="inherit"
            underline="hover"
            sx={{ ml: 1, mr: 1 }}
            target="_blank"
            rel="noreferrer"
          >
            咨询
          </Link>
          <Link
            to="/pricing"
            color="inherit"
            underline="hover"
            sx={{ ml: 1, mr: 1 }}
            component={RouterLink}
          >
            付费专业会员
          </Link>
        </Fragment>
      )}
      <Box sx={{ flex: 1 }}></Box>
      {props.signInStatus === PREMIUM && !props.ticketPremium && (
        <Button
          variant="text"
          size="large"
          sx={{ mr: 2 }}
          onClick={onClickBillingPortal}
        >
          付款管理
        </Button>
      )}
      {[VERYFING, PURCHASING, PREMIUM].includes(props.signInStatus) && (
        <Button
          variant="text"
          size="large"
          onClick={() => {
            firebase.auth().signOut();
            navigate("/");
            // @ts-ignore
            setSnackMessage("已注销");
            setShowSnack(true);
          }}
        >
          注销
        </Button>
      )}
      {props.signInStatus === NOTSIGNEDIN && (
        <Button
          variant="text"
          size="large"
          sx={{ mr: 2 }}
          to="/login"
          component={RouterLink}
        >
          登录
        </Button>
      )}
      {props.signInStatus === NOTSIGNEDIN && (
        <Button
          variant="contained"
          size="large"
          to="/pricing"
          component={RouterLink}
        >
          注册专业会员（免费试用一周）
        </Button>
      )}
      {props.signInStatus === VERYFING && (
        <Button
          variant="contained"
          size="large"
          to="/signup"
          component={RouterLink}
        >
          重新发送确认邮件
        </Button>
      )}
      {props.signInStatus === PURCHASING && (
        <Button
          variant="contained"
          size="large"
          to="/pricing"
          component={RouterLink}
        >
          填写付款方式
        </Button>
      )}
      {isLoading && (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Fragment>
      )}
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Toolbar>
  );
};

export default Header;
