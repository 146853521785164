import React from 'react';
import { Container, CssBaseline } from '@mui/material';

const Privacy = () => {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <h1>隐私政策</h1>
      <p>
        本隐私政策（以下称为"本隐私政策"）由株式会社ネクストラボ（以下称为"公司"）制定，旨在规定公司在提供的服务（以下称为"本服务"）中对用户（以下称为"用户"）所提供的个人信息的处理方式。用户在开始使用本服务的同时，即被视为同意本隐私政策。
      </p>
      <h3>个人信息的获取与管理</h3>
      <p>
        公司通过提供本服务或其他方式，适当和公正地获取用户的姓名、电子邮件地址、住址、电话号码、出生日期、包含信用卡信息的支付信息、身份证明及其他个人信息，并进行适当管理，同时为防止泄露、丢失、篡改等情况，尽最大努力予以注意。
      </p>
      <h3>利用目的</h3>
      <p>公司将基于以下目的使用用户的个人信息：</p>
      <ol>
        <li>提供本服务或与本服务相关的服务（以下称为"相关服务"）。</li>
        <li>进行本服务或相关服务、公司的活动等的通知、问卷调查。</li>
        <li>改善本服务、开发新服务和产品、分析、其他调查和研究。</li>
        <li>防止本服务的欺诈使用。</li>
        <li>在使用付费服务时的账单和支付处理。</li>
        <li>向除公司以外的广告主传达广告信息等。</li>
        <li>制作、使用及第三方提供已处理为无法识别个人的统计信息。</li>
        <li>本服务的维护、其他重要通知等的联系。</li>
        <li>应对上述相关业务、咨询等。</li>
      </ol>
      <h3>向第三方的披露与提供</h3>
      <p>
        除非符合以下任一项情况，公司将在未事先获得用户许可的情况下，不会向第三方披露或提供用户的个人信息：
      </p>
      <ol>
        <li>本隐私政策中另有规定或获得用户本人同意的情况。</li>
        <li>法律法规规定的情况。</li>
        <li>
          为保护他人的生命、身体或财产而必要的情形，但难以取得用户同意的情况下。
        </li>
        <li>
          为提高公共卫生或促进儿童健康成长而特别必要的情况，但难以取得用户同意的情况下。
        </li>
        <li>
          国家或地方公共团体等在实施公共事务时，需要合作，但如取得用户同意可能影响该事务的执行的情况。
        </li>
      </ol>
      <h3>业务委托</h3>
      <p>
        在向用户提供本服务时，公司可能将部分业务委托给第三方（包括日本境外的委托方），并在实现利用目的所需的范围内向该业务委托方提供个人信息。在此情况下，公司将适当管理和监督该业务委托方。
      </p>
      <h3>访问分析工具</h3>
      <p>
        公司使用谷歌的访问分析工具"Google
        Analytics"。此工具使用Cookie进行数据收集。收集的数据是匿名的，不会识别个人。您可以通过禁用Cookie来拒绝收集，因此请检查您使用的浏览器设置。有关该条款的详细信息，请参阅
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics服务条款
        </a>
        和
        <a
          href="https://policies.google.com/technologies/ads?hl=zh-CN"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google政策与条款页面
        </a>
        。
      </p>
      <h3>本隐私政策的变更</h3>
      <p>
        公司可能会变更本隐私政策。最新的隐私政策将发布在本页面上。自发布之日起，新隐私政策将生效。
      </p>
      <h3>关于个人信息处理的咨询</h3>
      <p>
        公司将根据法律法规，应对用户个人信息的披露、修正、删除、追加、停止使用、消除等请求。有关这些请求和咨询，请联系以下地址：
      </p>
      <p>
        株式会社ネクストラボ　隐私政策窗口
        <br />
        电子邮箱：read＠nextlabs.jp（请使用半角＠）
        <br />
        主题：关于隐私政策
      </p>
      <p>以上</p>
      <p>２０２２年１１月１１日　实施</p>
    </Container>
  );
};

export default Privacy;
